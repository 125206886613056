<template>
  <MarketComponent />
</template>

<script>
import MarketComponent from '../../components/market/MarketComponent.vue';

export default {
  name: 'Market',
  components: {
    MarketComponent,
  },
};
</script>

<style scoped>

</style>
