<template>
  <div class="market__wrapper" :class="{ 'on-mobile': $vuetify.breakpoint.mobile }">
    <div class="market__wrapper-content">
      <div v-if="checkData && !Number(form.is_maintenance)">
        <v-sheet class="position-relative market">
          <v-row>
            <v-col class="col-12">
              <div class="market__header">
                <div v-html="form.header"></div>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet class="position-relative market">
          <div class="cart__wrapper" v-if="totalItemsInCart">
            <v-col cols="auto">
              <v-badge color="success" left bottom overlap :content="totalItemsInCart">
                <v-btn fab color="white" @click="cartDrawer = !cartDrawer" elevation="2">
                  <v-icon dense>mdi-cart-variant</v-icon>
                </v-btn>
              </v-badge>
            </v-col>
          </div>
          <v-sheet class="items__wrapper" id="scrolling-techniques">
            <div class="items__content  pb-10">
              <v-container class="mt-3">
                <v-row no-gutters>
                  <v-col cols="12" class="text-center">
                    <h1>{{ form.name || "--" }}</h1>
                  </v-col>
                  <v-divider />
                </v-row>
              </v-container>
              <v-container v-if="categories.length">
                <v-row class="justify-center">
                  <v-col class="col-12 col-sm-6">
                    <VAutocomplete
                        v-model="choosenCategory"
                        dense
                        solo
                        :items="Object.values(categories)"
                        clearable
                        :item-text="'name'"
                        :item-value="'uuid'"
                        color="success"
                        item-color="success"
                        class="br-10"
                        :label="$t('crm.category')"
                        hide-details
                        @keypress="validationForTitle($event)"
                        @paste="validationForTitle($event)"
                      />
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="px-2 mb-10 ">
                <items-list
                  :listProducts="listProducts"
                  :loadingItemCard="loadingItemCard"
                  :loading="loading"
                />
              </v-container>
            </div>
          </v-sheet>

          <ItemDetailsDialog
            v-if="itemDetailsVisible"
            :visible="itemDetailsVisible"
            @close="itemDetailsVisible = false"
            :item="chosenItemDetailsData"
            :loading="loadingItemCard.loading"
          />
          <Loader />
        </v-sheet>
        <div class="market__footer">
          <div v-html="form.footer"></div>
        </div>
        <CartComponent
          :visible="cartDrawer"
          :cartUUID="formStorageData"
          @close="cartDrawer = false"
        />
      </div>
      <div class="market__null" v-else-if="!checkData">
        <h1>{{ $t("market.nullTitle") }}</h1>
      </div>
      <div class="market__null" v-if="Number(form.is_maintenance)">
        <div class="tech-work">
          <div class="tech-work__wrapper">
            <TechWork />
            <span class="tech-work__text grey--text text--darken-1">
              <span class="mr-2">
                На сайті проводяться <span class="grey--text text--darken-2">Технічні роботи</span>
              </span>
              <span class="rotate-animation">
                <v-icon color="grey darken-2">mdi-cog-outline</v-icon>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <Footer class="footer" />
  </div>
</template>

<script>
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import loader from "../../mixins/loader";
import CartComponent from "./cart/CartComponent.vue";
import ItemsList from "./items/ItemsList.vue";
import ItemDetailsDialog from "./items/ItemDetailsDialog.vue";
import crmForms from "@/services/request/crm/crmForms";
import EventBus from "@/events/EventBus";
import Loader from "../common/Loader.vue";
import Footer from "./Footer.vue";
import store from "../../store";
import {validationForTitle} from "@/mixins/helpers";

export default {
  name: "MarketComponent",
  mixins: [language, notifications, loader],
  components: {
    CartComponent,
    ItemsList,
    Loader,
    ItemDetailsDialog,
    Footer
  },
  data: () => ({
    checkData: true,
    cartDrawer: false,
    form: [],
    listProducts: [],
    loading: false,
    formStorageData: {},
    categories: [],
    cartData: {},
    totalItemsInCart: 0,
    loadingItemCard: {
      loading: false
    },
    itemDetailsVisible: false,
    chosenItemDetailsData: {},
    choosenCategory: null
  }),
  watch: {
    choosenCategory: {
      handler() {
        this.fetchData()
      }
    }
  },
  computed: {
    isInitCart() {
      const formUUID = this.$route.params.id;

      const storageData = JSON.parse(localStorage.getItem("formStorageData")) || [];

      return !!storageData.find(storage => storage.uuid_form == formUUID);
    }
  },
  methods: {
    validationForTitle,
    async isValidCart() {
      const isValidCart = await crmForms.cartInfo(this.formStorageData?.uuid_cart);
      if (!isValidCart) {
        this.initCart();
      }
    },
    onAddItemInCart(data) {
      this.addItemInCart(data);
    },
    async addItemInCart(data) {
      try {
        this.loadingItemCard = {
          loading: true,
          uuid: data.uuid
        };
        const formData = new FormData();
        formData.append("uuid_cart", this.formStorageData?.uuid_cart);
        formData.append("amount", 1);
        formData.append("uuid_form_item", data.uuid);
        if (data.uuid_form_item_option) {
          formData.append("uuid_form_item_option", data.uuid_form_item_option);
        }
        await crmForms.addItemInCart(formData);
        this.setSuccessNotification(this.$t("market.item_successfully_added"));

        this.cartDrawer = true;
        this.itemDetailsVisible = false;
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItemCard = {
          loading: false,
          uuid: data.uuid
        };
      }
    },
    async getCategories() {
      try {
        const data = {
          uuid_form: this.$route.params.id,
        };
        this.categories = await crmForms.getCategories(data);
      } catch (e) {
        console.log(e)
      }
    },
    async initCart() {
      try {

        const formUUID = this.$route.params.id;
        this.removeDataFromLocalStorage(formUUID);
        const formData = new FormData();
        formData.append("uuid_form", formUUID);
        this.cartData = await crmForms.initCart(formData);

        const params = {
          uuid_cart: this.cartData?.uuid,
          uuid_form: formUUID
        };
        this.saveDataToLocalStorage(params);
        this.formStorageData = params;
      } catch (e) {
        console.log(e)
      }
    },
    removeDataFromLocalStorage(formUUID) {
      let arr = JSON.parse(localStorage.getItem("formStorageData")) || [];
      arr = arr.filter(arr => arr.uuid_form !== formUUID);
      localStorage.setItem("formStorageData", JSON.stringify(arr));
    },
    saveDataToLocalStorage(data) {
      const arr = JSON.parse(localStorage.getItem("formStorageData")) || [];
      arr.push(data);
      localStorage.setItem("formStorageData", JSON.stringify(arr));
    },
    getDataByUUIDFromLocalStorage() {
      const formUUID = this.$route.params.id;
      const arr = JSON.parse(localStorage.getItem("formStorageData")) || [];
      this.formStorageData = arr.find(e => e.uuid_form === formUUID);
    },
    async fetchData() {
      try {
        this.loading = true;
        // this.setLoading(true);
        const formUUID = this.$route.params.id;
        this.form = (await crmForms.getFormByUUID(formUUID)) || {};
        await this.getProductList(this.form);
      } catch (e) {
        this.checkData = false;

        // console.log(e)
      } finally {
        // this.setLoading(false);
        this.loading = false;
      }
    },
    async getProductList({ uuid }) {
      try {
        const data = {}
        if (this.choosenCategory) {
          data.uuid_category = this.choosenCategory
        }
        this.listProducts = (await crmForms.getProductList(uuid, data)) || [];

      } catch (e) {
        console.log(e)
      }
    },
    setUtmToCookie() {
      this.$cookies.set("utm_campaign", this.form.utm_campaign || "");
      this.$cookies.set("utm_content", this.form.utm_content || "");
      this.$cookies.set("utm_source", this.form.utm_source || "");
      this.$cookies.set("utm_medium", this.form.utm_medium || "");
      this.$cookies.set("utm_term", this.form.utm_term || "");
    }
  },
  async mounted() {
    EventBus.$on("on-add-item-in-cart", data => {
      this.onAddItemInCart(data);
    });
    EventBus.$on("totalItemsInCart", data => {
      this.totalItemsInCart = data;
    });
    EventBus.$on("on-chosen-details", data => {
      this.chosenItemDetailsData = data;
      this.itemDetailsVisible = true;
    });
    this.getCategories()
    await this.fetchData();
    this.setUtmToCookie();
    document.title = this.form.name;


    if (!this.isInitCart) {
      this.initCart();
    } else {
      this.getDataByUUIDFromLocalStorage();
      this.isValidCart();
    }

  }
};
</script>

<style lang="scss">
// body,
// html {
//   scroll-behavior: smooth;
//   overflow-x: hidden !important;
//   overflow-y: auto !important;
// }

.market {
  &__wrapper-content {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 48px);
  }
  &__wrapper {
    min-height: 100vh;
    max-height: 100vh;
    position: relative;

    scroll-behavior: smooth;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    &.on-mobile {
      min-height:100vh;
      min-height: 100%;
      max-height:100vh;
      height: 100vh;
      max-height: -webkit-fill-available;
      // max-height: 100%;
    }
  }
  &__null {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    flex: 1 0;
  }
  .items {
    &__wrapper {
      // position: relative;
      // margin-top: 65px;
      display: block;
    }
    &__content {
      // position: relative;
      // min-height: 100vh;
      // max-height: 100vh;
      // scroll-behavior: smooth;
      // overflow-x: hidden !important;
      // overflow-y: auto !important;
    }
  }
  .cart {
    &__wrapper {
      position: fixed;
      top: 0%;
      right: 2%;
      z-index: 7;
    }
  }
}
.tech-work {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
  &__wrapper {
    margin: auto;
    max-width: 500px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__text {
    font-size: 25px;
    font-weight: 600;
    margin: 20px auto;
    display: flex;
    align-items: center;
  }
}
.rotate-animation {
  transform-origin: center center;
  -webkit-animation: rotateLoop 2s linear infinite;
}
@-webkit-keyframes rotateLoop {
  from {
    -webkit-transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(360deg);
  }
}
</style>
