<template>
  <v-dialog v-model="visibility" transition="dialog-bottom-transition" fullscreen>
    <v-card>
      <v-container>
        <v-card-title class="mb-7">
          <v-row no-gutters align="center" justify="space-between">
            <v-col cols="auto">
              <v-btn plain color="primary" @click="$emit('close')">
                <v-icon left>mdi-arrow-left</v-icon>
                {{ $t("btn.back") }}
              </v-btn>
            </v-col>
            <v-col cols="auto"> <v-icon @click="$emit('close')">mdi-close</v-icon> </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="px-5">
          <v-row>
            <v-col cols="12" sm="6" md="6" class="px-5">
              <div class="title__wrapper" v-if="!item.path_img">
                <img
                  :alt="item.name"
                  :src="require('@/assets/images/product-desc.png')"
                  class="image-placeholder unselectable br-10"
                  draggable="false"
                />
              </div>
              <div class="img__wrapper" v-else>
                <v-img
                  :src="item.path_img"
                  :alt="item.name"
                  class="unselectable image-placeholder br-10"
                  draggable="false"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                      style="min-height:60px"
                    >
                      <v-progress-circular
                        indeterminate
                        :size="30"
                        :width="3"
                        color="black"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <div class="d-flex justify-center align-center" v-if="images">
                <v-slide-group v-model="slideGroup" class="px-0 py-4 image-slider" show-arrows>
                  <v-slide-item v-for="image in images" :key="image.uuid">
                    <v-card class="ma-4" height="100" width="100" @click="showImgDialog(image)">
                      <v-img
                        :src="image.path_img_thumb || require('@/assets/images/supply-item.png')"
                        class="mb-0 pb-0 br-10 ma-auto h-100"
                        cover
                        style="width: 100px; height: 100px; object-fit: contain;"
                      />
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <div class="text-truncate text-h4">
                {{ item.name || "--" }}
              </div>
              <div class="grey--text text-truncate text-subtitle-1">
                {{ item.description || "--" }}
              </div>
              <div v-if="Number(item.discount) > 0" class="my-5">
                <v-row no-gutters align="center">
                  <span class="price font-weight-medium error--text text-h5 mr-2">
                    {{ item.price || 0 }} {{ $t("paymentPlan.uah") }}
                  </span>
                  <span
                    class="price  font-weight-regular grey--text text-decoration-line-through text-h5"
                  >
                    {{ getItemPrice(item) }} {{ $t("paymentPlan.uah") }}
                  </span>
                </v-row>
              </div>
              <div class="my-5" v-else>
                <span
                  class="price font-weight-medium text-h5"
                  style="line-height:45px"
                  :class="{ ' error--text': Number(item.discount) > 0 }"
                >
                  {{ getItemPrice(item) }} {{ $t("paymentPlan.uah") }}
                </span>
              </div>
              <div v-if="item?.options.length">
                <v-select
                  outlined
                  dense
                  v-model="item.uuid_form_item_option"
                  :items="item.options"
                  label="Оберіть опцію"
                  item-text="name"
                  item-value="uuid"
                  color="deep-purple lightning-2"
                  item-color="deep-purple lightning-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </div>
              <v-btn
                color="deep-purple lighten-2"
                class="white--text"
                @click="onAddItemInCart(item.uuid)"
                :loading="loading"
                :disabled="loading"
              >
                <v-icon left>
                  mdi-cart
                </v-icon>
                {{ $t("market.in_cart") }}
              </v-btn>
              <div class="pt-3">
                <span v-html="item.info"></span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-container>
    </v-card>
    <imgDialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
  </v-dialog>
</template>

<script>
import EventBus from "@/events/EventBus";
import galleryService from "@/services/request/gallery/galleryService";
import imgDialog from "@/components/products/imgDialog.vue";

export default {
  props: {
    visible: {
      require: true
    },
    item: {
      require: true
    },
    loading: {
      require: true
    }
  },
  components: {
    imgDialog
  },
  data: () => ({
    slideGroup: 0,
    images: [],
    imgDialog: false,
    imgpath: ""
  }),
  async mounted() {
    await this.getImagesList();
    if (this.item.options.length) {
      this.item.options.forEach(item => {
        if (item.is_default === "1") {
          this.item.uuid_form_item_option = item.uuid;
        }
      });
      if (!this.item.uuid_form_item_option) {
        this.item.uuid_form_item_option = this.item.options[0].uuid;
      }
    }
  },
  methods: {
    onAddItemInCart() {
      EventBus.$emit("on-add-item-in-cart", this.item);
    },
    getItemPrice({ price, discount }) {
      const discountToNumber = Number(discount);
      const priceToNumber = Number(price);
      if (discountToNumber > 0) {
        return Math.floor(priceToNumber * ((100 + discountToNumber) / 100));
      } else {
        return priceToNumber || 0;
      }
    },
    showImgDialog(product) {
      this.imgpath = product.path_img;
      this.imgDialog = true;
    },
    async getImagesList() {
      const formData = new FormData();
      formData.append("uuid_object", this.item.uuid);
      this.images = await galleryService.getList(formData);
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.image-placeholder {
  height: 500px;
  width: 100%;
  border-radius: 0;
  margin: 0;
}
</style>
<style lang="scss">
.image-slider {
  .v-slide-group__next,
  .v-slide-group__prev {
    min-width: 36px !important;
  }
}
</style>
